<svg xmlns="http://www.w3.org/2000/svg" width="115" height="35" [class.menu-item]="hover" class="d-none d-sm-block" viewBox="0 0 115 35">
	<g transform="translate(75 19)">
		<path
			class="user-icon"
			d="M15.5,2A13.5,13.5,0,1,0,29,15.5,13.5,13.5,0,0,0,15.5,2Zm0,4.05a4.05,4.05,0,1,1-4.05,4.05A4.045,4.045,0,0,1,15.5,6.05Zm0,19.17a9.721,9.721,0,0,1-8.1-4.347c.041-2.687,5.4-4.158,8.1-4.158s8.059,1.471,8.1,4.158A9.721,9.721,0,0,1,15.5,25.22Z"
			transform="translate(-69 -17)"
			fill="#616060" />
		<path
			class="rect"
			d="M4,1A3,3,0,0,0,1,4V31a3,3,0,0,0,3,3H111a3,3,0,0,0,3-3V4a3,3,0,0,0-3-3H4M4,0H111a4,4,0,0,1,4,4V31a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z"
			transform="translate(-75 -19)"
			fill="rgba(97,96,96,0.64)" />
		<text class="text" transform="translate(0 3)" fill="#616060" font-size="14" font-family="Roboto-Regular, Roboto">
			<tspan text-anchor="middle">{{ inputTranslate }}</tspan>
		</text>
	</g>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" [class.menu-item]="hover" class="d-sm-none" viewBox="0 0 35 35">
	<g transform="translate(19 19)">
		<path
			class="user-icon"
			d="M15.5,2A13.5,13.5,0,1,0,29,15.5,13.5,13.5,0,0,0,15.5,2Zm0,4.05a4.05,4.05,0,1,1-4.05,4.05A4.045,4.045,0,0,1,15.5,6.05Zm0,19.17a9.721,9.721,0,0,1-8.1-4.347c.041-2.687,5.4-4.158,8.1-4.158s8.059,1.471,8.1,4.158A9.721,9.721,0,0,1,15.5,25.22Z"
			transform="translate(-17 -17)"
			fill="#616060" />
	</g>
</svg>
