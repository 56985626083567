import { NgClass } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { convertPascalCasetoCamelCase } from '@app/helpers/case-converter';
import { isInIframe } from '@app/helpers/iframe-helper';
import { isNullish } from '@commonHelpers/math-utils';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@commonNodeModules/@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from '@commonNodeModules/@ngx-translate/core';
import { WatchListStructure } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { SoftwareIntegrationCallbackService } from '@services/software-integration-callback.service';
import { UserAuthorizationService } from '@services/user-authorization.service';
import { WatchlistActionService } from '@services/watchlist-action.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-watchlist-header-navigation',
	templateUrl: './watchlist-header-navigation.component.html',
	styleUrls: ['./watchlist-header-navigation.component.scss'],
	standalone: true,
	imports: [NgClass, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, TranslateModule]
})
export class WatchlistHeaderNavigationComponent implements OnInit, OnDestroy {

	@ViewChild("watchlistDropdown") watchlistDropdown: NgbDropdown;


	constructor(
		private userAuthorizationService: UserAuthorizationService,
		private router: Router,
		private route: ActivatedRoute,
		private watchlistActionService: WatchlistActionService,
		private softwareIntegrationCallbackService: SoftwareIntegrationCallbackService
	) { }

	private initWatchlistSubscription: Subscription;

	async ngOnInit() {
		if (isInIframe()) {
			if (this.userAuthorizationService.isLoggedIn()) {
				await this.watchlistActionService.initIFrameWatchlist()
			} else {
				await this.watchlistActionService.initIFrameWatchlist()
				this.initWatchlistSubscription = this.userAuthorizationService.initWatchlist.subscribe(async () => this.watchlistActionService.initIFrameWatchlist())
			}
		} else if (!this.userAuthorizationService.isLoading()) {
			await this.watchlistActionService.initWatchlist()
		} else {
			this.initWatchlistSubscription = this.userAuthorizationService.initWatchlist.subscribe(async () => this.watchlistActionService.initWatchlist())
		}
	}

	ngOnDestroy(): void {
		this.initWatchlistSubscription?.unsubscribe();
	}
	public get watchlistIcon() {
		return this.itemsCount === 0 ? '/assets/orca/watchlist/merkliste.svg' : '/assets/orca/watchlist/merkliste-aktiv.svg'
	}

	public navigateToWatchlist() {
		const url = this.router.createUrlTree([`/watchlist`, convertPascalCasetoCamelCase(WatchListStructure[WatchListStructure.Catalog])])
		if (this.canNavigate()) {
			this.watchlistActionService.navigateToWatchlist(url.toString());
		}
	}

	public canNavigate() {
		return isNullish(this.route.snapshot.params.watchlistSection) && !this.softwareIntegrationCallbackService.hasSession();
	}

	public get itemsCount() {
		const watchlist = this.watchlistActionService.getWatchlist()
		return watchlist.watchlistItems?.length ?? 0
	}

	public get maxCount() {
		return this.watchlistActionService.getMaxCount()
	}

	public get defaultImgSize() {
		return !isInIframe() || this.maxCount;
	}

	public onHoverDropdown() {
		if (this.canNavigate() && !isNullish(this.maxCount)) {
			this.watchlistDropdown.open()
		}

	}

	public onLeaveDropdown() {
		if (this.canNavigate()) {
			this.watchlistDropdown.close()
		}
	}

}
