import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { CatalogViewServiceBase } from "@app/super/catalog-view-service-base";
import { LoadingComponent } from '@commonComponents/loading/loading.component';
import { isNullish } from '@commonHelpers/math-utils';
import { TranslateModule } from "@commonNodeModules/@ngx-translate/core";
import { ViewType } from "@enums/view-type";
import { CatalogViewGroupDto, ContactFormType } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { TranslationService } from "@services/translation.service";
import { ViewTypeService } from "@services/view-type.service";
import { Subscription } from "rxjs";
import { NumberFormatPipe } from "../../pipes/numberFormat";
import { CatalogContactComponent } from "../../public/catalog/catalog-contact/catalog-contact.component";

@Component({
	selector: "app-cataloginfoviewer",
	templateUrl: "./cataloginfoviewer.component.html",
	styleUrls: ["./cataloginfoviewer.component.scss"],
	standalone: true,
	imports: [CatalogContactComponent, LoadingComponent, DatePipe, NumberFormatPipe, TranslateModule]
})
export class CatalogInfoViewerComponent implements OnInit, OnDestroy {
	public catalogInfo: CatalogViewGroupDto;
	@Input() catalogKey: string;
	@Input() catalogViewService: CatalogViewServiceBase;

	@Output() contactFormOpen = new EventEmitter<any>();

	public viewType = ViewType;
	private currentViewType$ = this.viewTypeService.currentViewType$
	private subscription: Subscription;
	public currentViewType: ViewType
	public additionalCategoriesTexts: string[];
	public countValues: CountValues[] | undefined;

	constructor(
		private viewTypeService: ViewTypeService,
		private translationService: TranslationService,
		private cdRef: ChangeDetectorRef,
	) { }

	async ngOnInit() {

		const head = document.getElementsByTagName('head')[0];
		let element: HTMLLinkElement = document.querySelector(`link[rel='canonical']`) || null
		element = document.createElement('link') as HTMLLinkElement;
		head.appendChild(element);
		element.setAttribute('rel', 'canonical')
		element.setAttribute('href', `${window.location.origin}/catalog/${this.catalogKey}`)

		this.catalogInfo = await this.catalogViewService.getCatalogMeta(this.catalogKey);
		this.setCountValues();
		this.subscription = this.currentViewType$.subscribe(currentViewType => {
			this.currentViewType = currentViewType
			this.cdRef.detectChanges();
		})

		if (this.catalogInfo?.featureToggles?.simpleFeatureToggles?.LicenseAdditionalCategories) {
			this.additionalCategoriesTexts = this.catalogInfo.categoriesResourceKeys.map(m =>
				this.translationService.getByKey(m.categoryGroupResourceKey)
				+ " | "
				+ this.translationService.getByKey(m.categoryResourceKey)
			);
		}
	}

	private setCountValues() {
		if (isNullish(this.catalogInfo)) {
			return;
		}
		const countValues: CountValues[] = [];
		if (this.catalogInfo.countTexts > 0) {
			countValues.push({ count: this.catalogInfo.countTexts, translationKey: 'attachments.texts' })
		}
		if (this.catalogInfo.countImagesAndVideos > 0) {
			countValues.push({ count: this.catalogInfo.countImagesAndVideos, translationKey: 'attachments.ImageAndVideo' })
		}
		if (this.catalogInfo.countDocuments > 0) {
			countValues.push({ count: this.catalogInfo.countDocuments, translationKey: 'attachments.Documents' })
		}
		if (this.catalogInfo.countCadAndBim > 0) {
			countValues.push({ count: this.catalogInfo.countCadAndBim, translationKey: 'attachments.CadAndBim' })
		}
		if (this.catalogInfo.countLinks > 0) {
			countValues.push({ count: this.catalogInfo.countLinks, translationKey: 'attachments.Links' })
		}
		this.countValues = countValues;
	}

	ngOnDestroy(): void {
		const head = document.getElementsByTagName('head')[0];
		const element: HTMLLinkElement = document.querySelector(`link[rel='canonical']`) || null
		if (!isNullish(element)) {
			head.removeChild(element);
		}
		this.subscription?.unsubscribe();
	}

	openContactForm(contactPersonId: number) {
		this.contactFormOpen.emit({ contactFormType: ContactFormType.CatalogContact });
	}
}

export interface CountValues {
	translationKey: string;
	count: number;
}