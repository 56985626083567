
import { NgClass } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { TrackLoginRequestParams, TrackShareCatalogItemParams } from "@app/models/tracking/track-event-param-type";
import { ButtonComponent } from "@commonComponents/button/button.component";
import { isNullish } from "@commonHelpers/math-utils";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, NgbTooltip } from "@commonNodeModules/@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@commonNodeModules/@ngx-translate/core";
import { ConfirmDialogService } from "@commonServices/confirm-dialog.service";
import { ActionButtonContentType } from "@enums/CatalogContentType";
import { CatalogDetailsType } from "@enums/catalog-details-type";
import { ViewType } from "@enums/view-type";
import { SoftwareIntegrationApiCallbackType } from "@interfaces/HttpClient/AccountApiPublicModels";
import { LoginSource, ShareCatalogItemType } from "@interfaces/HttpClient/AnalyticsApiTrackingModels";
import { CatalogIntegrationType, ContactFormType, ExportLimit, ExportSettingsDto, FeatureToggleValuesDto, ViewGroup, ViewGroupItemDto, Viewer } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { ICatalogRoutingParams } from "@interfaces/iCatalogRoutingParams";
import { AccountService } from "@services/account.service";
import { ALERT_DISPLAY_TYPES, ALERT_POSITION_TYPES, AlertService } from "@services/alert.service";
import { AnalyticsTrackingFacadeService } from "@services/analytics-tracking-facade.service";
import { BimobjectService } from "@services/bimobject.service";
import { CadenasDownloadService } from "@services/cadenas-download.service";
import { CatalogTreeService } from "@services/catalog-tree.service";
import { ClipboardService } from "@services/clipboard.service";
import { MetadataDownloadService } from "@services/metadata-download.service";
import { SoftwareIntegrationCallbackService } from "@services/software-integration-callback.service";
import { TranslationService } from "@services/translation.service";
import { UserAuthorizationService } from "@services/user-authorization.service";
import { ViewTypeService } from "@services/view-type.service";
import { WatchlistActionService } from "@services/watchlist-action.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subject, Subscription, merge } from "rxjs";
import { AlertComponent } from "../../../components/alert/alert.component";
import { ActionButtonsExportComponent } from "../action-buttons-export/action-buttons-export.component";
import { ExportDialogComponent } from "../export-dialog/export-dialog.component";

@Component({
	selector: "app-action-buttons",
	templateUrl: "./action-buttons.component.html",
	styleUrls: ["./action-buttons.component.scss"],
	standalone: true,
	providers: [CadenasDownloadService,
		MetadataDownloadService,
	],
	imports: [NgClass, AlertComponent, ButtonComponent, NgbTooltip, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, TranslateModule, ExportDialogComponent, ActionButtonsExportComponent]
})
export class ActionButtonsComponent implements OnInit, OnChanges, OnDestroy {

	@Input() exportSettings: ExportSettingsDto;
	@Input() selectedItem: ViewGroupItemDto;
	@Input() isFolderCatalogItem: boolean;
	@Input() routingParams: ICatalogRoutingParams;
	@Input() catalogIntegration: CatalogIntegrationType;
	@Input() catalogDetailsType: CatalogDetailsType;

	@Output() contactFormOpen = new EventEmitter<any>();
	@Output() isExportDialogOpen = new EventEmitter<boolean>();
	@ViewChildren("dropdown") downloadDropdowns: QueryList<NgbDropdown>;

	public ViewGroup = ViewGroup;
	public contactFormPopupVisible: Subject<void> = new Subject<void>();
	public isLoggedIn = false;
	public featureToggles: FeatureToggleValuesDto;
	public copyToClipboardIsUsable = false;
	private isDownloading = false;
	public ALERT_POSITION_TYPES = ALERT_POSITION_TYPES;

	private subscription: Subscription;
	private downloadSubscription: Subscription;
	private openDialogSubscription: Subscription;
	public contactbuttonSize: string;
	public currentViewType: ViewType;
	public viewType = ViewType;
	private exportDialogOpen: boolean;
	public visibleDialogPosition: { height?: number, width?: number } = {};
	public actionButtonType = ActionButtonType

	public actionButtonContentType = ActionButtonContentType;

	public actionButtons: ActionButton[] = [];
	public showActionButtons = false;
	public loadExportsCopy = false;

	constructor(
		private alertService: AlertService,
		private translationService: TranslationService,
		private catalogTreeService: CatalogTreeService,
		private metadataDownloadService: MetadataDownloadService,
		private cdRef: ChangeDetectorRef,
		private userAuthorizationService: UserAuthorizationService,
		private router: Router,
		private accountService: AccountService,
		private trackingFacadeService: AnalyticsTrackingFacadeService,
		private viewTypeService: ViewTypeService,
		private deviceDetectorService: DeviceDetectorService,
		private clipboardService: ClipboardService,
		private watchlistActionService: WatchlistActionService,
		private softwareIntegrationCallbackService: SoftwareIntegrationCallbackService,
		private cadenasDownloadService: CadenasDownloadService,
		private confirmDialogService: ConfirmDialogService,
		private bimobjectService: BimobjectService
	) { }
	ngOnChanges(changes: SimpleChanges): void {
		this.setActionButtons();
	}

	async ngOnInit() {
		this.isLoggedIn = this.userAuthorizationService.isLoggedIn();
		this.copyToClipboardIsUsable = await this.clipboardService.isCopyToClipboardIsUsable()
		this.subscription = this.viewTypeService.currentViewType$.subscribe(async currentViewType => {
			this.contactbuttonSize = currentViewType === ViewType.Desktop ? 'lg' : 'sm'
			this.currentViewType = currentViewType;
		})

		merge(this.metadataDownloadService.watchlistExportAnimation, this.metadataDownloadService.downloadAnimation).subscribe(queue => {
			this.isDownloading = queue > 0;
			this.setActionButtons();
		})

		this.openDialogSubscription = merge(this.cadenasDownloadService.openDialog, this.bimobjectService.openDialog, this.watchlistActionService.openDialog)
			.subscribe(() => {
				const idx = this.actionButtons.findIndex(button => button.type === ActionButtonType.Export);
				this.downloadDropdowns.get(idx).open()
			});

		this.watchlistActionService.getWatchlistChangedSubscription().subscribe(() => {
			this.setActionButtons();
			this.cdRef.detectChanges()
		})
		this.setActionButtons(true);
	}

	private setActionButtons(showActionButtons = false) {

		const actionButtons = [];
		if ((this.catalogDetailsType === CatalogDetailsType.WatchlistDefault || this.catalogDetailsType === CatalogDetailsType.WatchlistUserdefined) &&
			!this.isViewerFrom([Viewer.WatchlistRoot, Viewer.WatchlistUserdefinedFolder, Viewer.WatchlistUserdefinedRoot])) {
			actionButtons.push({
				type: ActionButtonType.CatalogNavigation,
				tooltipKey: 'watchlist.navigationTooltip',
				routerLinkParams: this.goToCatalogView(),
				target: '_blank',
				ariaLabel: 'Navigate to Catalog',
				iconImg: 'eye-mask-img'
			})
		}

		if (!this.softwareIntegrationCallbackService.hasSession() && !isNullish(this.routingParams?.catalogItemGuid) && this.catalogIntegration !== CatalogIntegrationType.CatalogItemViewer) {
			actionButtons.push({
				type: ActionButtonType.Watchlist,
				tooltipKey: 'catalog-view.detail-actions.watchlist.' + (this.isWatchlistMarked ? 'remove' : 'add'),
				iconImg: this.isWatchlistMarked ? 'remove-from-watchlist-img' : 'add-to-watchlist-img',
				ariaLabel: 'Watchlist Toggle',
				openDelay: 800
			})
		}

		if (this.isViewerFrom([Viewer.WatchlistUserdefinedFolder])) {
			actionButtons.push({
				type: ActionButtonType.UserdefinedDelete,
				tooltipKey: 'watchlist.userDefined.action.enabled.delete',
				iconImg: 'delete-mask-img',
				ariaLabel: 'Watchlist delete Position',
			})
		}

		if (!isNullish(this.selectedItem?.viewer) && !(this.isViewerFrom([Viewer.CatalogInfoViewer]) && this.catalogDetailsType === CatalogDetailsType.Public)) {
			actionButtons.push({
				type: ActionButtonType.Export,
				tooltipKey: this.isFolderCatalogItem ? 'catalog-view.detail-actions.folder-download' : 'global.download',
				ariaLabel: 'Export',
				downloading: this.isDownloading
			})
		}

		if (this.copyToClipboardIsUsable && (!this.isFolderCatalogItem) && this.isViewerFrom([Viewer.HtmlViewer, Viewer.LinkViewer]) && this.deviceDetectorService.browser !== "Safari") {
			actionButtons.push({
				type: ActionButtonType.CopyWindow,
				tooltipKey: 'global.clipboard',
				ariaLabel: 'Copy To Clipboard',
				iconImg: 'file-copy-mask-img'
			})
		}

		if ((!this.isFolderCatalogItem) && this.isViewerFrom([Viewer.ImageViewer, Viewer.PdfViewer, Viewer.FileViewer, Viewer.FileVideoViewer, Viewer.IfcViewer, Viewer.AutodeskViewer])) {
			actionButtons.push({
				type: ActionButtonType.CopyMenu,
				tooltipKey: 'global.clipboard',
				ariaLabel: 'Copy To Clipboard',
				iconImg: 'file-copy-mask-img'
			})
		}

		if (!this.softwareIntegrationCallbackService.hasSession() && !this.isWatchlistItem) {
			actionButtons.push({
				type: ActionButtonType.Share,
				tooltipKey: 'catalog-view.detail-actions.share.' + (!isNullish(this.routingParams?.key) && isNullish(this.routingParams?.catalogItemGuid) ? 'catalog-tooltip' : 'tooltip'),
				ariaLabel: 'Share',
				iconImg: 'share-mask-img'
			})
		}

		// Nötig damit Menü nicht geschlossen wird, wenn actionButtons als neues Object angelegt wird, und somit für die NGBDropdownkomponente onDestroy mit close() aufgerufen wird 
		if (JSON.stringify(actionButtons) !== JSON.stringify(this.actionButtons)) {
			this.actionButtons = actionButtons;
		}

		// Nötig wegen kleinen layoutshifting
		if (showActionButtons) {
			this.showActionButtons = true;
		}
	}

	public async action(type: ActionButtonType, dropdown?: NgbDropdown) {
		switch (type) {
			case ActionButtonType.Watchlist: {
				await this.watchlistActionService.watchListToggle({
					catalogItemGuid: this.routingParams.catalogItemGuid,
					positionType: this.viewTypeService.getPositionType(),
					isFolder: this.isFolderCatalogItem,
					showDialogAllwayInDelete: !isNullish(this.routingParams.watchlistSection) && this.isWatchlistMarked,
					allwaysDelete: false,
					text: this.selectedItem.title
				});
				break;
			}
			case ActionButtonType.UserdefinedDelete: {
				this.watchlistActionService.triggerDeleteUserDefinedItem();
				break;
			}
			case ActionButtonType.CopyWindow: {
				// Erst Exportdateien laden dann Clickevent ausführen
				this.loadExportsCopy = true;
				this.clipboardService.copyClick()
				break;
			}
			case ActionButtonType.Share:
			case ActionButtonType.CopyMenu:
			case ActionButtonType.Export: {
				dropdown.open();
				break;
			}
		}
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
		this.downloadSubscription?.unsubscribe();
		this.openDialogSubscription?.unsubscribe();
	}

	public setDialogPosition(visibleDialogPosition) {
		this.visibleDialogPosition = visibleDialogPosition;
	}

	private get isWatchlistMarked() {
		const marked = this.watchlistActionService.isMarked(this.routingParams.catalogItemGuid)
		return marked?.isMarked || marked?.isPartlyMarked || !isNullish(this.routingParams.watchlistSection);
	}

	public get hasSoftwareIntegrationCallbackButton() {
		return !isNullish(this.selectedItem?.catalogItemId) && this.softwareIntegrationCallbackService.hasCallback(SoftwareIntegrationApiCallbackType.Export);
	}

	public get hasCatalogItemLinkCallbackButton() {
		return !isNullish(this.selectedItem?.catalogItemId) && this.softwareIntegrationCallbackService.hasCallback(SoftwareIntegrationApiCallbackType.CatalogItemLink);
	}

	openContactForm() {
		this.contactFormOpen.emit({ contactFormType: ContactFormType.CatalogContact, undefined });
	}

	openChange(isOpen: boolean) {
		this.exportDialogOpen = isOpen;
	}

	public get dropdownContainerClass() {
		return this.exportDialogOpen && this.currentViewType !== ViewType.Desktop ? 'dropdown-position' : 'dropdown-desktop-position'
	}



	public async onSoftwareIntegrationCallbackClick() {
		this.cdRef.detectChanges();
		const exportSettings = this.exportSettings;
		const positions = await this.catalogTreeService.countCatalogItems(this.selectedItem.catalogItemId, this.routingParams.key);

		// if user is logged in
		if (this.userAuthorizationService.isLoggedIn()) {
			// fully unlimited or limited to (500) for registered depending on maxRegisteredUsersPositions
			if (exportSettings.exportLimit === ExportLimit.Unlimited || positions <= exportSettings.maxRegisteredUsersPositions) {
				// export with position warning (may take some time)
				if (positions >= exportSettings?.minPositionsForWarning) {
					this.userIsAllowedToExportButWithTimeWarning(positions);
				} else {
					// export without warning
					this.softwareIntegrationCallbackService.export()
				}
			}
			// to many positions, only occures if exportLimit === 0 and positions > maxRegisteredUsersPositions
			else {
				this.userIsNotAllowedToExportUnderAnyCircumstances();
			}
		}
		// if user is not logged in
		else {
			// valid export positions if user is not logged && positions are less than maxEverybodyPositions
			if (positions <= exportSettings.maxEverybodyPositions) {
				// export with position warning (may take some time)
				if (positions >= exportSettings?.minPositionsForWarning) {
					this.userIsAllowedToExportButWithTimeWarning(positions);
				} else {
					// export without warning
					this.softwareIntegrationCallbackService.export()
				}
			}
			else {
				// if user would be allowed to export, if he´s logged in allow him to login
				if (exportSettings.exportLimit === ExportLimit.Unlimited || positions <= exportSettings.maxRegisteredUsersPositions) {
					this.userWouldBeAllowedToExportIfLoggedIn();
				}
				// forbidden for everyone for who´s not logged-in and logged-in Users
				else {
					this.userIsNotAllowedToExportUnderAnyCircumstances();
				}
			}
		}
	}

	private userIsAllowedToExportButWithTimeWarning(positions) {
		this.confirmDialogService.open({	// Nur mit Abbrechen Button
			title: 'catalog-view.export-dialog.position-many-items',
			confirm: 'global.ok',
			description: 'catalog-view.export-dialog.position-many-items-description',
			reject: 'global.cancel',
			descriptionVars: {
				openTag: '<span class="fw-bold">',
				closeTag: '</span>',
				count: positions
			}
		}, async () => this.softwareIntegrationCallbackService.export(), () => {

		});
	}

	private userIsNotAllowedToExportUnderAnyCircumstances() {
		this.confirmDialogService.open({	// Nur mit Abbrechen Button
			title: 'catalog-view.export-dialog.export-too-many-items',
			confirm: '',
			description: 'catalog-view.export-dialog.export-forbiddenLoggedIn',
			reject: 'global.cancel'
		}, () => { }, undefined);
	}

	private userWouldBeAllowedToExportIfLoggedIn() {
		this.confirmDialogService.open({	// Mit Login und Abbrechen Button
			title: 'catalog-view.export-dialog.export-too-many-items',
			confirm: 'global.login-registration',
			description: 'catalog-view.export-dialog.export-forbiddenNotLoggedIn',
			reject: 'global.cancel'
		}, async () => {
			this.trackingFacadeService.handleTrackEvent(new TrackLoginRequestParams(
				LoginSource.Export,
				this.routingParams.key ?? "",
				this.routingParams.catalogItemGuid,
				isNullish(this.selectedItem.id) ? null : this.selectedItem.id
			));
			await this.trackingFacadeService.commitAllTrackEvents();
			await this.accountService.login()
		}, undefined);
	}

	public async onCatalogItemLinkCallbackClick() {
		this.cdRef.detectChanges();
		this.softwareIntegrationCallbackService.getCatalogItemLink();
	}

	async onCopyLink(): Promise<void> {
		this.trackingFacadeService.handleTrackEvent(
			new TrackShareCatalogItemParams(
				this.routingParams.key,
				this.routingParams.catalogItemGuid,
				ShareCatalogItemType.CopyLink
			))

		if (navigator.clipboard) {
			await navigator.clipboard.writeText(this.getPermalink());
			this.alertService.sendMessage(this.viewTypeService.getPositionType(), ALERT_DISPLAY_TYPES.SUCCESS, this.translationService.getByKey("catalog-view.detail-actions.share.copy-link-msg"))
		} else {
			this.alertService.sendMessage(this.viewTypeService.getPositionType(), ALERT_DISPLAY_TYPES.WARNING, this.translationService.getByKey("global.alert.copy-description-apifailure"))
		}
	}

	public onClickSendPerMail() {
		this.trackingFacadeService.handleTrackEvent(
			new TrackShareCatalogItemParams(
				this.routingParams.key,
				this.routingParams.catalogItemGuid,
				ShareCatalogItemType.SendPerMail
			))
	}

	public getEmailHref(): string {
		const subject = this.translationService.getByKey("catalog-view.detail-actions.share.send-email.subject");
		const body = this.translationService.getByKey("catalog-view.detail-actions.share.send-email.body", { permalink: this.getPermalink() });
		return `mailto:?subject=${subject}&body=${body}`
	}

	private getPermalink(): string {
		const catalogKey = this.routingParams?.key;
		const catalogItemGuid = this.routingParams?.catalogItemGuid;
		const permalink = window.location.origin + "/catalog/" + catalogKey + (catalogItemGuid ? "/catalogItem/" + catalogItemGuid : "");
		return permalink;
	}


	public get isWatchlistItem() {
		return this.isViewerFrom([Viewer.WatchlistRoot, Viewer.WatchlistUserdefinedFolder, Viewer.WatchlistUserdefinedRoot]);
	}

	private isViewerFrom(viewers: Viewer[]) {
		return viewers?.includes(this.selectedItem?.viewer) ?? false;
	}

	public onUserDefinedDeleteClick() {
		this.watchlistActionService.triggerDeleteUserDefinedItem();
	}

	private goToCatalogView(): string[] {
		const result = ["/catalog", this.routingParams.key];
		if (!isNullish(this.routingParams.catalogItemGuid)) {
			result.push("catalogItem");
			result.push(this.routingParams.catalogItemGuid);
		}
		return result;
	}
}

export enum ActionButtonType {
	CatalogNavigation = 0,
	Watchlist = 1,
	UserdefinedDelete = 2,
	Export = 3,
	CopyWindow = 4,
	CopyMenu = 5,
	Share = 6
}

export interface ActionButton {
	type: ActionButtonType,
	tooltipKey: string,
	ariaLabel: string;
	iconImg?: string,
	routerLinkParams?: string[],
	target?: string,
	openDelay?: number,
	downloading?: boolean
	dropDownClass?: string;
}