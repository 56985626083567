
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { isInIframe } from '@app/helpers/iframe-helper';
import { TrackLoginRequestParams } from '@app/models/tracking/track-event-param-type';
import { ButtonComponent } from '@commonComponents/button/button.component';
import { LoadingComponent } from '@commonComponents/loading/loading.component';
import { delayHelper } from '@commonHelpers/delay-helper';
import { isNullish } from '@commonHelpers/math-utils';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from '@commonNodeModules/@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@commonNodeModules/@ngx-translate/core';
import { environment } from '@environments/environment';
import { LoginSource } from "@interfaces/HttpClient/AnalyticsApiTrackingModels";
import { AccountService } from '@services/account.service';
import { AnalyticsTrackingFacadeService } from '@services/analytics-tracking-facade.service';
import { ApplicationInsightsService } from '@services/application-insights.service';
import { PopupService } from '@services/popup.service';
import { UserAuthorizationService } from '@services/user-authorization.service';
import { Subscription, timer } from 'rxjs';
import { UserMenuButtonImgComponent } from '../user-menu-button-img/user-menu-button-img.component';
import { UserMenuComponent } from '../user-menu.component';
;

@Component({
	selector: 'app-user-menu-context',
	templateUrl: './user-menu-context.component.html',
	styleUrls: ['./user-menu-context.component.scss'],
	standalone: true,
	imports: [NgbDropdown, UserMenuButtonImgComponent, NgbDropdownToggle, LoadingComponent, NgbDropdownMenu, ButtonComponent, UserMenuComponent, TranslateModule]
})
export class UserMenuContextComponent implements OnInit, OnChanges, OnDestroy {

	constructor(
		private accountService: AccountService,
		private userAuthorizationService: UserAuthorizationService,
		private aiService: ApplicationInsightsService,
		private trackingFacadeService: AnalyticsTrackingFacadeService,
		private cdRef: ChangeDetectorRef,
		private popupService: PopupService
	) {
		this.refreshLoginFailureSubscription = this.userAuthorizationService.loginFailure.subscribe(async () => this.loaded = true)
	}

	@Input() catalogKey: string;
	@Input() target: string;
	@Input() rel: string;
	@Input() hover: boolean;

	@ViewChild("userMenuDropdownNotLoggendIn") userMenuDropdownNotLoggendIn: NgbDropdown;
	private refreshLoginFailureSubscription: Subscription;
	private isHoveredOverMenu = false;
	public loaded = false;



	ngOnInit(): void {
		if (isInIframe()) {
			this.accountService.refreshEndInvokeIframe().subscribe(() => {
				this.loaded = true
			}
			);
		}
	}

	ngOnDestroy(): void {
		this.refreshLoginFailureSubscription?.unsubscribe();
	}

	public get isLoggedIn(): boolean {
		return this.accountService.isLoggedIn();
	}

	public onHoverDropdown() {
		this.userMenuDropdownNotLoggendIn.open();
	}

	public onLeaveDropdown() {
		this.userMenuDropdownNotLoggendIn.close()
	}

	public leaveDropdownButton(event: MouseEvent) {
		this.isHoveredOverMenu = false;
		const domRect = (event.target as HTMLElement)?.getBoundingClientRect();
		if (!isNullish(domRect) && domRect.y + domRect.height > event.clientY) {
			this.onLeaveDropdown();
		} else {
			timer(300).subscribe(() => {
				if (!this.isHoveredOverMenu) {
					this.onLeaveDropdown();
				}
			})
		}

	}

	async ngOnChanges(changes: SimpleChanges) {
		if (!this.userAuthorizationService.isLoading()) {
			if (this.isLoggedIn) {
				this.userAuthorizationService.refreshMenu.next();
			} else {
				this.loaded = true;
			}
		}
	}

	public userMenuChanged() {
		this.cdRef.detectChanges();
	}

	public async login(): Promise<void> {
		this.userAuthorizationService.clearData();

		this.trackingFacadeService.handleTrackEvent(new TrackLoginRequestParams(LoginSource.UserMenu, this.catalogKey))
		await this.trackingFacadeService.commitAllTrackEvents();
		this.cdRef.detectChanges();
		delayHelper(async () => {
			await this.accountService.login();
		});

	}

	public async logout(): Promise<void> {
		this.userAuthorizationService.clearData();
		this.aiService.clearAuthenticatedUserContext();
		await this.accountService.logout();

	}

	public hoverCheck() {
		this.isHoveredOverMenu = true;
	}

	public openLoginProfits() {
		this.popupService.openLoginProfits(this.catalogKey);
		this.userMenuDropdownNotLoggendIn.close()
	}

	public hasSignup(): boolean {
		return environment.msal.signUpFlow !== "";
	}

	public async signup(): Promise<void> {
		this.userAuthorizationService.clearData()
		await this.accountService.signup();
		// JP 07.10.2021 Für Signup kein explizites Tracking erforderlich
	}

	public get initials() {
		const account = this.accountService.getAccount();
		const claims = account?.idTokenClaims as any;
		return (claims?.given_name?.[0] ?? '') + (claims?.family_name?.[0] ?? '')?.toUpperCase();
	}

}
