// ACHTUNG!!! SEO-kritische Komponente, Direkte Importe und Backendaufrufe sollten so gering wie nur möglich gehalten werden 


import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SoftwareIntegrationCallbackService } from '@services/software-integration-callback.service';
import { HelpPublicComponent } from "../../../components/help-public/help-public.component";
import { UserMenuContextComponent } from "../../../components/user-menu/user-menu-context/user-menu-context.component";
import { WatchlistHeaderNavigationComponent } from "../../../components/watchlist-header-navigation/watchlist-header-navigation.component";
import { HeaderBurgerMenuComponent } from "../header-burger-menu/header-burger-menu.component";

@Component({
	selector: 'app-mobile-header',
	standalone: true,
	templateUrl: './mobile-header.component.html',
	styleUrls: ['./mobile-header.component.scss'],
	imports: [RouterModule, HeaderBurgerMenuComponent, HelpPublicComponent, UserMenuContextComponent, WatchlistHeaderNavigationComponent]
})
export class MobileHeaderComponent implements OnInit {
	@Input() catalogKey: string
	public showWatchlist = true;

	constructor(private softwareIntegrationService: SoftwareIntegrationCallbackService) {

	}
	ngOnInit(): void {
		this.showWatchlist = !this.softwareIntegrationService.hasSession();
	}



}
