import { EventEmitter, Injectable } from '@angular/core';
import { isInIframe } from '@app/helpers/iframe-helper';
import { TrackLoginSuccessParams } from '@app/models/tracking/track-event-param-type';
import { ResetLoginParams } from '@app/models/tracking/track-history-param-type';
import { delayHelper } from '@commonHelpers/delay-helper';
import { isNullish, isUndefined } from '@commonHelpers/math-utils';
import { MsalBroadcastService } from '@commonNodeModules/@azure/msal-angular';
import { EventType, InteractionStatus } from '@commonNodeModules/@azure/msal-browser';
import { environment } from '@environments/environment';
import { AccountAuthorizationDisplayInfoDto, AccountLanguage, EntityRoleAssignmentDisplayInfoDto, Role, RolesDto, UserMenuDisplayInfoDto } from '@interfaces/HttpClient/AccountApiPublicModels';
import { timer } from 'rxjs';
import { AccountService } from './account.service';
import { AnalyticsTrackingFacadeService } from './analytics-tracking-facade.service';
import { ApplicationInsightsService } from './application-insights.service';
import { GlobalVarService } from './global-var.service';
import { SoftwareIntegrationCallbackService } from './software-integration-callback.service';

@Injectable({
	providedIn: "root"
})
export class UserAuthorizationService {
	private msalLoading = true;

	private accountAuthorizationDisplayInfos: AccountAuthorizationDisplayInfoDto;
	private effectiveRoles: RolesDto;
	private defaultDisplayMenu: UserMenuDisplayInfoDto;
	private userMenuDisplayCatalogIdInfo = new Map<number, UserMenuDisplayInfoDto>();
	private userMenuDisplayKeyInfo = new Map<string, UserMenuDisplayInfoDto>();
	private userMenuDisplayCompanyInfo = new Map<number, UserMenuDisplayInfoDto>();
	public refreshLanguageUserMenu = new EventEmitter<AccountLanguage>();
	public refreshMenu = new EventEmitter<void>();
	public initWatchlist = new EventEmitter<void>();
	public loginFailure = new EventEmitter<void>();
	public refreshComponents = new EventEmitter<void>();
	private loadingEmit = new EventEmitter<void>();
	private currentLoginEventType: EventType;

	constructor(
		private accountService: AccountService,
		private msalBroadcastService: MsalBroadcastService,
		private aiService: ApplicationInsightsService,
		private trackingFacadeService: AnalyticsTrackingFacadeService,
		private globalVarService: GlobalVarService,
		private softwareIntegrationCallbackService: SoftwareIntegrationCallbackService
	) {
	}

	clearData(): void {
		this.accountAuthorizationDisplayInfos = undefined;
		this.effectiveRoles = undefined;
		this.userMenuDisplayKeyInfo = new Map<string, UserMenuDisplayInfoDto>();
		this.userMenuDisplayCatalogIdInfo = new Map<number, UserMenuDisplayInfoDto>();
		this.userMenuDisplayCompanyInfo = new Map<number, UserMenuDisplayInfoDto>();
		this.defaultDisplayMenu = undefined;

	}

	isLoggedIn(): boolean {
		return this.accountService.isLoggedIn();
	}

	async hasRole(role: Role): Promise<boolean> {
		if (!await this.ensureAccountInfosLoaded()) {
			return false;
		}
		return this.effectiveRoles?.roles.includes(role);
	}

	public isLoading() {
		return this.msalLoading
	}

	subscribeState() {

		this.msalBroadcastService.msalSubject$.subscribe(async subject => {
			if (subject.eventType === EventType.LOGIN_SUCCESS || subject.eventType === EventType.LOGOUT_SUCCESS || subject.eventType === EventType.LOGIN_FAILURE) {
				this.currentLoginEventType = subject.eventType;
			}
			if (subject.eventType === EventType.LOGIN_FAILURE && subject.error.message.startsWith('access_denied: AADB2C90118')) {
				await this.accountService.resetPwd()
			};
		})

		if (isInIframe()) {
			this.accountService.refreshStartInvokeIframe().subscribe(() => this.msalLoading = true);
			this.accountService.refreshEndInvokeIframe().subscribe(async () => this.handleLogin())
		}

		this.msalBroadcastService.inProgress$.subscribe(async progress => {
			if (progress === InteractionStatus.None) {
				await this.handleLogin();
			}
		});
		if (environment.needsAdditionalAuthenticationInformation) {
			delayHelper(async () => {
				const needsAuthentication = await this.accountService.getNeedsAuthentication();
				if (needsAuthentication) {
					timer(5000).subscribe(async () => {
						if (!this.isLoggedIn()) {
							await this.accountService.login()
						}
					})
				}
			})

		}

	}

	private async handleLogin() {
		await this.trackingFacadeService.initTracking();
		this.handleLoginStatus();
		await this.globalVarService.accountRedirectHandle();
		this.loadingEmit.next(null);
		this.loadingEmit.complete();
		this.softwareIntegrationCallbackService.msalLoaded();
		this.msalLoading = false;
		await this.softwareIntegrationCallbackService.startSession();
		if (this.isLoggedIn()) {
			const language = await this.accountService.getLanguage();
			if (!isNullish(language)) {
				this.globalVarService.setLanguage(language);
			}
			this.refreshMenu.next();
		} else if (environment.needsAdditionalAuthenticationInformation) {
			const needsAuthentication = await this.accountService.getNeedsAuthentication();
			if (needsAuthentication) {
				await this.accountService.login();
			}
		}
		this.initWatchlist.emit();
	}

	// Es darf erst auf Sessions zugegriffen werden wenn der msalprozess abgeschlossen wurde und die 'Session gesetzt wurde.
	private handleLoginStatus() {
		switch (this.currentLoginEventType) {
			case EventType.LOGIN_SUCCESS: {
				this.trackingFacadeService.handleTrackEvent(new TrackLoginSuccessParams())
				break;
			}
			case EventType.LOGOUT_SUCCESS: {
				this.trackingFacadeService.handleTrackHistory(new ResetLoginParams())
				break;
			}
			case EventType.LOGIN_FAILURE: {
				this.trackingFacadeService.handleTrackHistory(new ResetLoginParams())
				this.aiService.clearAuthenticatedUserContext();
				this.loginFailure.next();
				break;
			}
		}
	}

	async hasCatalogRole(catalogId: string, role: Role): Promise<boolean> {
		if (!await this.ensureAccountInfosLoaded()) {
			return false;
		}

		if (!this.effectiveRoles?.rolesByCatalogId) {
			return false;
		}

		const catalogRoles = this.effectiveRoles?.rolesByCatalogId?.[catalogId];

		if (!catalogRoles) {
			return false;
		}

		return catalogRoles.includes(role);
	}

	async hasCompanyRole(companyId: string, role: Role): Promise<boolean> {
		if (!await this.ensureAccountInfosLoaded()) {
			return false;
		}

		if (!this.effectiveRoles?.rolesByCompanyId) {
			return false;
		}

		const companyRoles = this.effectiveRoles?.rolesByCompanyId?.[companyId];

		if (!companyRoles) {
			return false;
		}

		return companyRoles.includes(role);
	}

	async getCatalogs(): Promise<EntityRoleAssignmentDisplayInfoDto[]> {
		if (!await this.ensureAccountInfosLoaded()) {
			return;
		}
		return this.accountAuthorizationDisplayInfos.activeCatalogSpecificRoles;
	}

	async getCompanies(): Promise<EntityRoleAssignmentDisplayInfoDto[]> {
		if (!await this.ensureAccountInfosLoaded()) {
			return Array();
		}
		return this.accountAuthorizationDisplayInfos.activeCompanySpecificRoles;
	}

	private async ensureAccountInfosLoaded(): Promise<boolean> {
		if (this.msalLoading) {
			await this.loadingEmit.toPromise();
		}
		if ((isNullish(this.accountAuthorizationDisplayInfos) || isNullish(this.effectiveRoles))) {
			await this.loadAccountInfos();
		}
		return true;
	}

	private async loadAccountInfos(): Promise<void> {
		if (this.msalLoading) {
			await this.loadingEmit.toPromise();
		}
		if (this.isLoggedIn()) {
			this.accountAuthorizationDisplayInfos = await this.accountService.getUserAuthorizationDisplayInfo();
			this.effectiveRoles = await this.accountService.getUserEffectiveRoles();
		} else {
			this.clearData();
		}

	}

	public async getMenuDisplayInfoDtoByCatalogKey(catalogKey: string): Promise<UserMenuDisplayInfoDto> {
		let userMenu = await this.initUserMenu();
		if (isUndefined(userMenu)) {
			if (!this.userMenuDisplayKeyInfo.has(catalogKey)) {
				this.userMenuDisplayKeyInfo.set(catalogKey, await this.accountService.getUserMenuDisplayInfoByCatalogKey(catalogKey));
			}
			userMenu = this.setDefaultUserMenu(this.userMenuDisplayKeyInfo.get(catalogKey))
		}
		return userMenu;
	}

	public async getMenuDisplayInfo(): Promise<UserMenuDisplayInfoDto> {
		let userMenu = await this.initUserMenu();
		if (isUndefined(userMenu)) {
			if (isNullish(this.defaultDisplayMenu)) {
				this.defaultDisplayMenu = await this.accountService.getUserMenuDisplayInfo();
			}
			userMenu = this.setDefaultUserMenu(this.defaultDisplayMenu);
		}
		return userMenu;
	}

	public async getEffectiveRoles(): Promise<Role[]> {
		if (!await this.ensureAccountInfosLoaded()) {
			return undefined;
		}
		return this.effectiveRoles?.roles;
	}

	private setDefaultUserMenu(userMenu: UserMenuDisplayInfoDto) {
		if (!userMenu?.isAdmin) {
			this.defaultDisplayMenu = userMenu;
		}
		return userMenu;
	}

	private async initUserMenu() {
		if (!isNullish(this.defaultDisplayMenu) && !this.defaultDisplayMenu.isAdmin) {
			return this.defaultDisplayMenu;
		}
		return undefined;
	}
}
