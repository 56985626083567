<div class="w-100 h-100">
	@switch (mainViewer) {
		@case (viewer.LinkViewer) {
			@defer {
				<app-linkviewer
					[catalogKey]="catalogKey"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
		@case (viewer.ImageViewer) {
			@defer {
				<app-imageviewer
					[catalogKey]="catalogKey"
					[attachments]="attachments"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
		@case (viewer.PdfViewer) {
			@defer {
				<app-pdfviewer
					[catalogKey]="catalogKey"
					[attachments]="attachments"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
		@case (viewer.AutodeskViewer) {
			@defer {
				<app-autodeskviewer
					[catalogKey]="catalogKey"
					[attachments]="attachments"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
		@case (viewer.BimObjectViewer) {
			@defer {
				<app-bimobjectviewer
					[catalogKey]="catalogKey"
					[attachments]="attachments"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[catalogLanguage]="catalogLanguage"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
		@case (viewer.CadenasViewer) {
			@defer {
				<app-cadenasviewer
					[catalogKey]="catalogKey"
					[attachments]="attachments"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
		@case (viewer.FileViewer) {
			@defer {
				<app-fileviewer
					[catalogKey]="catalogKey"
					[attachments]="attachments"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
		@case (viewer.IfcViewer) {
			@if (featureToggles?.simpleFeatureToggles.CatalogViewAutoDeskForgeForIfc) {
				@defer {
					<app-autodeskviewer
						[catalogKey]="catalogKey"
						[attachments]="attachments"
						[attachment]="attachment"
						[catalogDetailsType]="catalogDetailsType"
						[catalogItemGuid]="catalogItemGuid"
						[url]="url"
						[featureToggles]="featureToggles"
						[currentRoutingParams]="routingParams" />
				}
			} @else {
				@defer {
					<app-ifcviewer
						[catalogKey]="catalogKey"
						[attachments]="attachments"
						[attachment]="attachment"
						[catalogDetailsType]="catalogDetailsType"
						[catalogItemGuid]="catalogItemGuid"
						[url]="url"
						[featureToggles]="featureToggles"
						[currentRoutingParams]="routingParams" />
				}
			}
		}
		@case (viewer.VimeoVideoViewer) {
			@defer {
				<app-vimeovideoviewer
					[catalogKey]="catalogKey"
					[attachments]="attachments"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
		@case (viewer.YoutubeVideoViewer) {
			@defer {
				<app-ytvideoviewer
					[catalogKey]="catalogKey"
					[attachments]="attachments"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
		@case (viewer.FileVideoViewer) {
			@defer {
				<app-filevideoviewer
					[catalogKey]="catalogKey"
					[attachments]="attachments"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
		@case (viewer.TextViewer) {
			@defer {
				<app-textviewer
					[catalogKey]="catalogKey"
					[attachments]="attachments"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
		@case (viewer.ProtocolViewer) {
			@defer {
				<app-protocolviewer
					[catalogKey]="catalogKey"
					[attachments]="attachments"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
		@case (viewer.FolderContentViewer) {
			@defer {
				<app-folder-viewer
					[catalogViewService]="catalogViewService"
					[catalogKey]="catalogKey"
					[attachments]="attachments"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
		@case (viewer.CatalogInfoViewer) {
			@defer {
				<app-cataloginfoviewer
					[catalogKey]="catalogKey"
					[catalogViewService]="catalogViewService"
					(contactFormOpen)="contactFormOpen?.emit($event)" />
			}
		}
		@case (viewer.CatalogContactViewer) {
			@defer {
				<app-catalogcontactviewer
					[catalogKey]="catalogKey"
					[catalogViewService]="catalogViewService"
					(contactFormOpen)="contactFormOpen?.emit($event)" />
			}
		}
		@case (viewer.CatalogRelationsViewer) {
			@defer {
				<app-catalogrelationsviewer
					[currentRoutingParams]="routingParams"
					[queryParams]="queryParams"
					[relatedCatalogs]="catalogContentViewersViewModel.relatedCatalogs"
					[languageCatalogs]="catalogContentViewersViewModel.foreignLanguageCatalogs" />
			}
		}
		@case (viewer.CatalogContactPersonViewer) {
			@defer {
				<app-catalogcontactperson-viewer
					[catalogViewService]="catalogViewService"
					(contactFormOpen)="contactFormOpen?.emit($event)" />
			}
		}
		@case (viewer.WatchlistRoot) {
			@defer {
				<app-watchlist-rootviewer />
			}
		}
		@case (viewer.WatchlistUserdefinedRoot) {
			@defer {
				<app-watchlist-userdefined-rootviewer />
			}
		}
		@case (viewer.WatchlistUserdefinedFolder) {
			@defer {
				<app-folder-viewer
					[catalogViewService]="catalogViewService"
					[catalogKey]="catalogKey"
					[attachments]="attachments"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
		@case (viewer.SustainabilityViewer) {
			@defer {
				<app-sustainability-viewer
					[catalogViewService]="catalogViewService"
					[catalogKey]="catalogKey"
					[attachments]="attachments"
					[attachment]="attachment"
					[catalogDetailsType]="catalogDetailsType"
					[catalogItemGuid]="catalogItemGuid"
					[url]="url"
					[featureToggles]="featureToggles"
					[currentRoutingParams]="routingParams" />
			}
		}
	}
</div>
