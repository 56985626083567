<div class="py-lg-1">
	<a
		ngbDropdown
		[href]="helpdeskUrl"
		target="_blank"
		rel="noreferrer"
		class="btn toggle-dropdown border-0 responsive-margin"
		#helpDropdown="ngbDropdown"
		placement="bottom-right"
		dropdownClass="dropdown-zindex"
		[container]="'body'"
		(mouseenter)="onHoverDropdown()"
		(mouseleave)="onLeaveDropdown()">
		<img
			ngbDropdownToggle
			src="/assets/orca/header/help.svg"
			loading="lazy"
			decoding="async"
			alt="help"
			style="height: 24px; width: 24px" />
		<div
			id="dropdownHelp"
			ngbDropdownMenu
			aria-labelledby="watchlist-dropdown"
			(mouseenter)="onHoverDropdown()"
			class="help-dropdown"
			(mouseleave)="onLeaveDropdown()">
			<div class="w-100 px-4 pt-3 pb-3 d-flex flex-column align-items-center bg-gray-200" style="max-width: calc(100vw - 30px)">
				<div class="h5 text-center" style="line-height: 24px">
					{{ 'public-help.header' | translate }}
				</div>
				<div class="text-center">
					<span>{{ 'public-help.open' | translate }}</span>
				</div>
			</div>
		</div>
	</a>
</div>
