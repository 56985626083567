<div
	ngbDropdown
	(click)="navigateToWatchlist()"
	role="link"
	[class.cursor-unset]="!canNavigate()"
	class="btn responsive-margin toggle-dropdown border-0"
	#watchlistDropdown="ngbDropdown"
	placement="bottom-right"
	dropdownClass="dropdown-zindex"
	[container]="'body'"
	(mouseenter)="onHoverDropdown()"
	(mouseleave)="onLeaveDropdown()">
	<div ngbDropdownToggle id="watchlist-dropdown" class="watchlist-header-content-height" role="link">
		<div [ngClass]="defaultImgSize ? 'default-img-size' : 'large-img-size'">
			<img [src]="watchlistIcon" loading="lazy" decoding="async" alt="{{ 'watchlist.header' | translate }}" />
		</div>
		@if (maxCount) {
			<div class="watchlistbutton">
				{{ 'watchlist-header-navigation.counter' | translate: { count: itemsCount, maxCount: maxCount } }}
			</div>
		}
	</div>

	<div
		[class.d-none]="!this.canNavigate()"
		id="watchlistHelp"
		ngbDropdownMenu
		(mouseenter)="onHoverDropdown()"
		(mouseleave)="onLeaveDropdown()">
		<div class="w-100 px-4 pt-3 pb-3 d-flex flex-column align-items-center bg-gray-200" style="max-width: calc(100vw - 30px)">
			<div class="h5 text-center" style="line-height: 24px">
				{{ 'watchlist-header-navigation.title' | translate }}
			</div>
			<div class="text-center">
				<span>{{
					'watchlist-header-navigation.description.' + (itemsCount === 1 ? 'singular' : 'plural')
						| translate: { count: itemsCount }
				}}</span>
			</div>
		</div>
	</div>
</div>
<ng-content></ng-content>
