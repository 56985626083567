@if (!linkUrl && !routerLinkParams) {
	<button
		[type]="type"
		[attr.aria-label]="ariaLabel"
		[class.w-100]="isW100"
		[class.h-100]="isH100"
		class="btn btn-{{ variant }}"
		[ngStyle]="{ 'width.rem': width }"
		[ngClass]="classes"
		(click)="handleClick($event)"
		[disabled]="disabled || isLoading"
		ngbTooltip="{{ tooltip }}"
		tooltipClass="ngbTooltipClass"
		container="body"
		[openDelay]="800">
		<ng-template *ngTemplateOutlet="innerButton"></ng-template>
	</button>
}
@if (linkUrl) {
	<a
		[href]="linkUrl"
		[target]="target"
		[rel]="rel"
		[class.w-100]="isW100"
		[class.h-100]="isH100"
		class="btn btn-{{ variant }}"
		[ngStyle]="{ 'width.rem': width }"
		[ngClass]="classes"
		(click)="handleClick($event)"
		ngbTooltip="{{ tooltip }}"
		tooltipClass="ngbTooltipClass"
		container="body"
		[openDelay]="800">
		<ng-template *ngTemplateOutlet="innerButton"></ng-template>
	</a>
}

@if (routerLinkParams) {
	<a
		[routerLink]="routerLinkParams"
		queryParamsHandling="merge"
		[target]="target"
		[class.w-100]="isW100"
		[class.h-100]="isH100"
		class="btn btn-{{ variant }}"
		[ngStyle]="{ 'width.rem': width }"
		[ngClass]="classes"
		(click)="handleClick($event)"
		ngbTooltip="{{ tooltip }}"
		tooltipClass="ngbTooltipClass"
		container="body"
		[openDelay]="800">
		<ng-template *ngTemplateOutlet="innerButton"></ng-template>
	</a>
}

<ng-template #innerButton>
	<div [class.h-100]="isH100" class="d-flex align-items-center position-relative justify-content-center">
		<div [class.h-100]="isH100">
			<ng-content></ng-content>
		</div>
		@if (isLoading) {
			<div class="position-absolute">
				<lib-loading [color]="'gray-700'"></lib-loading>
			</div>
		}
	</div>
</ng-template>
