import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isNullish } from '@commonHelpers/math-utils';
import { AccountLanguage } from '@interfaces/HttpClient/AccountApiPublicModels';
import { PrepareImportForUploadDto } from '@interfaces/HttpClient/CatalogApiModels';
import { IWatchlist, IWatchlistDragStart } from '@interfaces/iWatchlist';
import { ILocalSessionItem } from './session.service';
import { ILocalUserItem } from './user.service';

declare const hasAnotherTab;
@Injectable({
	providedIn: 'root'
})
export class GlobalVarService {


	constructor(private router: Router) { }
	private readonly catalogNavigationVarName = "catalogNavigation";
	private readonly searchNavigationVarName = "searchNavigation";
	private readonly catalogUploadingIdVarName = "catalogUploadingId";
	private readonly softwareIntegrationSessionId = "softwareIntegrationSessionId"
	private readonly isInSoftwareIntegration = "isInSoftwareIntegration"
	private readonly integrationUrl = "integrationUrl"
	private readonly maxInt = 2147483647;
	private readonly language = "language";
	private readonly currentBuild = "currentBuild";
	private readonly watchlist = "watchlist";
	private readonly watchlistItemDragStart = "watchlistItemDragStart";
	private readonly accountHandle = "accountHandle";
	private readonly userEdit = "userEdit";
	private readonly onboardingId = "onboardingId";
	private readonly onboardingLanguage = "onboardingLanguage";
	private readonly loginError = "loginError";
	private readonly baseKey = "technicalKeys";
	private readonly session = "ade_session";
	private readonly user = "ade_user";
	private static storageAvoidVariableMap = new Map<string, string>();
	private static lastSession: ILocalSessionItem;
	private static lastUser: ILocalUserItem;
	private static lastWatchlistItemDragStart: IWatchlistDragStart;
	private static lastWatchlist: IWatchlist = {
		watchlistItems: [],
		isLogin: false
	}

	// Taberkennung läuft über die index.html, da es sonst während des Ladens der Angularkomponenten beim Initialieren zu Verfälschungen kommt.
	public hasAnotherTab() {

		return hasAnotherTab;
	}


	public getMaxInt(): number {
		return this.maxInt
	}

	public setCatalogNavigation(value: string) {
		this.setSessionStorageVar(this.catalogNavigationVarName, value);
	}

	public getCatalogNavigation(): string {
		return this.getSessionStorageVar(this.catalogNavigationVarName);
	}

	public setSearchNavigation(value: string) {
		this.setSessionStorageVar(this.searchNavigationVarName, value);
	}

	public getSearchNavigation(): string {
		return this.getSessionStorageVar(this.searchNavigationVarName);
	}

	public getSoftwareIntegrationSessionId() {
		return this.getSessionStorageVar(this.softwareIntegrationSessionId);
	}

	public setsoftwareIntegrationSessionId(sessionId: string) {
		this.setSessionStorageVar(this.isInSoftwareIntegration, "true");
		return this.setSessionStorageVar(this.softwareIntegrationSessionId, sessionId);
	}

	public setLanguage(language: AccountLanguage) {
		if (language !== undefined) {
			this.setLocalStorageVar(this.language, language.toString());
		} else {
			this.setLocalStorageVar(this.language, AccountLanguage.German.toString());
		}
	}

	public getLanguage(): AccountLanguage {
		return +this.getLocalStorageVar(this.language);
	}

	public getCatalogUploading(): PrepareImportForUploadDto {
		const catalogUploadIgnData = this.getSessionStorageVar(this.catalogUploadingIdVarName)
		return !isNullish(catalogUploadIgnData) ? JSON.parse(catalogUploadIgnData) : null;
	}

	public isInSoftwareIntegrationMode(): boolean {
		const softwareIntegration = this.getSessionStorageVar(this.isInSoftwareIntegration)
		return !isNullish(softwareIntegration) ? JSON.parse(softwareIntegration) : false;
	}

	public setCatalogUploading(uploading: any) {
		this.setSessionStorageVar(this.catalogUploadingIdVarName, JSON.stringify(uploading));
	}

	public getCurrrentBuild() {
		return this.getLocalStorageVar(this.currentBuild);
	}

	public setCurrrentBuild(build: string) {
		if (!isNullish(build)) {
			this.setLocalStorageVar(this.currentBuild, build);
		}
	}

	public deleteCatalogUploading() {
		this.deleteSessionStorageVar(this.catalogUploadingIdVarName);
	}

	public setAccountHandleWindowClose() {
		this.setSessionStorageVar(this.accountHandle, 'true')
	}

	public getOnboardingId() {
		return +(this.getSessionStorageVar(this.onboardingId) ?? 0)
	}

	public getOnboardingLanguage() {
		return (this.getSessionStorageVar(this.onboardingLanguage) ?? "")
	}

	public setOnboardingId(onboardingId: number) {
		if (!isNullish(onboardingId) && onboardingId !== 0) {
			this.setSessionStorageVar(this.onboardingId, onboardingId?.toString())
		}
	}

	public setOnboardingLanguage(onboardingLanguage: string) {
		if (!isNullish(onboardingLanguage) && onboardingLanguage !== "") {
			this.setSessionStorageVar(this.onboardingLanguage, onboardingLanguage)
		}
	}

	public deleteOnboardingId() {
		this.deleteSessionStorageVar(this.onboardingId);
	}

	public async accountRedirectHandle() {
		if (this.getSessionStorageVar(this.accountHandle) === 'true') {
			await this.router.navigate(['integration', 'account-handler'], { replaceUrl: true });
		}
		if (!isNullish(this.getOnboardingId()) && this.getOnboardingId() !== 0) {
			// await this.router.navigate(['/onboarding'], { queryParams: { onBoardingId: this.getOnboardingId() }, replaceUrl: true });
			await this.router.navigate(['/onboarding'], { queryParams: { onBoardingId: this.getOnboardingId(), language: this.getOnboardingLanguage() }, replaceUrl: true });
			this.deleteOnboardingId();
		}
	}

	public setUserEdit(location: string) {
		this.setSessionStorageVar(this.userEdit, location)
	}

	public getUserEdit() {
		return this.getSessionStorageVar(this.userEdit)
	}

	public deleteUserEdit() {
		this.deleteSessionStorageVar(this.userEdit);
	}

	public hasRedirect() {
		return !isNullish(this.getUserEdit()) || !(isNullish(this.getOnboardingId()) || this.getOnboardingId() === 0) || !isNullish(this.getSessionStorageVar(this.accountHandle))
	}

	public getWatchlist(): IWatchlist {
		try {
			const watchlist = JSON.parse(this.getLocalStorageVar(this.watchlist));
			GlobalVarService.lastWatchlist = watchlist;
			return watchlist;
		} catch {
			return GlobalVarService.lastWatchlist;
		}

	}

	public setWatchlist(watchlist: IWatchlist) {
		this.setLocalStorageVar(this.watchlist, JSON.stringify(watchlist));
		GlobalVarService.lastWatchlist = watchlist;
	}

	public deleteWatchlist() {
		this.deleteLocalStorageVar(this.watchlist);
	}

	public getWatchlistItemDragStart(): IWatchlistDragStart {
		try {
			const watchlistItemDragStart = JSON.parse(this.getLocalStorageVar(this.watchlistItemDragStart));
			GlobalVarService.lastWatchlistItemDragStart = watchlistItemDragStart;
			return watchlistItemDragStart;
		} catch {
			return GlobalVarService.lastWatchlistItemDragStart;
		}

	}

	public setWatchlistItemDragStart(watchlistItemDragStart: IWatchlistDragStart) {
		this.setLocalStorageVar(this.watchlistItemDragStart, JSON.stringify(watchlistItemDragStart));
		GlobalVarService.lastWatchlistItemDragStart = watchlistItemDragStart;
	}

	public deleteWatchlistItemDragStart() {
		this.deleteLocalStorageVar(this.watchlistItemDragStart);
	}

	public getSession(): ILocalSessionItem {
		try {
			const session = JSON.parse(this.getLocalStorageVar(this.session, true));
			GlobalVarService.lastSession = session;
			return session;
		} catch {
			return GlobalVarService.lastSession;
		}
	}

	public setSession(session: ILocalSessionItem) {
		this.setLocalStorageVar(this.session, JSON.stringify(session), true);
		GlobalVarService.lastSession = session;
	}

	public deleteSession() {
		this.deleteLocalStorageVar(this.session, true);
	}


	public getUser(): ILocalUserItem {
		try {
			const user = JSON.parse(this.getLocalStorageVar(this.user, true));
			GlobalVarService.lastUser = user;
			return user;
		} catch {
			return GlobalVarService.lastUser;
		}
	}

	public setUser(user: ILocalUserItem) {
		this.setLocalStorageVar(this.user, JSON.stringify(user), true);
		GlobalVarService.lastUser = user;
	}

	public deleteUser() {
		this.deleteLocalStorageVar(this.user, true);
	}

	public setLoginError() {
		this.setLocalStorageVar(this.loginError, 'true');
	}

	public getLoginError() {
		return !isNullish(this.getLocalStorageVar(this.loginError));
	}

	public deleteLoginError() {
		this.deleteLocalStorageVar(this.loginError)
	}

	public getIntegrationUrl(): string {
		return this.getSessionStorageVar(this.integrationUrl);
	}

	public setIntegrationUrl(url: string) {
		this.setSessionStorageVar(this.integrationUrl, url);
	}


	public setSessionStorageVar(key: string, value: string, standalone?: boolean) {
		try {
			if (standalone) {
				sessionStorage.setItem(key, value);
			} else {
				const itemsString = sessionStorage.getItem(this.baseKey)
				const items = this.getParsedItems(itemsString);
				items[key] = value;
				sessionStorage.setItem(this.baseKey, JSON.stringify(items));
			}

		} catch (e) {
			GlobalVarService.storageAvoidVariableMap.set(key, value);
		}
	}

	public getSessionStorageVar(key: string, standalone?: boolean): any {
		try {
			if (standalone) {
				return sessionStorage.getItem(key);
			} else {
				const itemsString = sessionStorage.getItem(this.baseKey)
				const items = this.getParsedItems(itemsString);
				if (isNullish(items[key])) {
					return null;
				}
				return items[key];
			}

		} catch (e) {
			return GlobalVarService.storageAvoidVariableMap.get(key);
		}
	}

	public deleteSessionStorageVar(key: string, standalone?: boolean) {
		try {
			if (standalone) {
				sessionStorage.deleteItem(key);
			} else {
				const itemsString = sessionStorage.getItem(this.baseKey)
				const items = this.getParsedItems(itemsString);
				delete items[key]
				sessionStorage.setItem(this.baseKey, JSON.stringify(items));
			}
		} catch (e) {
			return GlobalVarService.storageAvoidVariableMap.delete(key);
		}
	}

	public setLocalStorageVar(key: string, value: string, standalone?: boolean) {
		try {
			if (standalone) {
				localStorage.setItem(key, value)
			} else {
				const itemsString = localStorage.getItem(this.baseKey)
				const items = this.getParsedItems(itemsString);
				items[key] = value;
				localStorage.setItem(this.baseKey, JSON.stringify(items));
			}

		} catch (e) {
			GlobalVarService.storageAvoidVariableMap.set(key, value);
		}
	}

	public getLocalStorageVar(key: string, standalone?: boolean): any {
		try {
			if (standalone) {
				return localStorage.getItem(key);
			} else {
				const itemsString = localStorage.getItem(this.baseKey)
				const items = this.getParsedItems(itemsString);
				if (isNullish(items[key])) {
					return null;
				}
				return items[key];
			}

		} catch (e) {
			return GlobalVarService.storageAvoidVariableMap.get(key);
		}
	}

	public deleteLocalStorageVar(key: string, standalone?: boolean) {
		try {
			if (standalone) {
				localStorage.removeItem(key);
			} else {
				const itemsString = localStorage.getItem(this.baseKey)
				const items = this.getParsedItems(itemsString);
				delete items[key]
				localStorage.setItem(this.baseKey, JSON.stringify(items));
			}

		} catch (e) {
			return GlobalVarService.storageAvoidVariableMap.delete(key);
		}
	}

	private getParsedItems(parsed: string) {
		let items;
		try {
			items = !isNullish(parsed) ? JSON.parse(parsed) : {};
		} catch {
			items = {};
		}
		return items;
	}


	public checkStorage(): boolean {
		try {
			localStorage.getItem(this.baseKey);
			return true
		} catch (e) {
			return false
		}
	}

	public insertFormerWatchlistAndClearStorage() {
		try {
			const build = localStorage.getItem(this.currentBuild)
			if (!isNullish(build)) {
				const watchlistData = localStorage.getItem(this.watchlist)
				const formerWatchlist = !isNullish(watchlistData) ? JSON.parse(watchlistData) : undefined;
				Object.keys(localStorage).filter(key => !key.includes('-b2c')).forEach(key => {
					localStorage.removeItem(key)
				});
				Object.keys(sessionStorage).forEach(key => {
					sessionStorage.removeItem(key)
				});
				this.setCurrrentBuild(build);
				if (formerWatchlist) {
					this.setWatchlist(formerWatchlist);
				}
			}
		} catch {

		}
	}


}
