import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { GlobalTranslationVar } from "@app/class/GlobalTranslationVar";
import { ProtectedResourceScopes } from "@commonNodeModules/@azure/msal-angular";
import { InteractionType } from "@commonNodeModules/@azure/msal-browser";
import { Language } from "@enums/language";
import { environment } from "@environments/environment";
import { MsalB2CInterceptor } from "./msalb2c.interceptor";

export const interactionType = InteractionType.Redirect;
export const baseAuthority = "https://" + environment.msal.tenant + ".b2clogin.com/" + environment.msal.tenant + ".onmicrosoft.com/";
export const signinAuthority = baseAuthority + environment.msal.signInFlow;
export const pwdResetAuthority = baseAuthority + environment.msal.passwordResetFlow;
export const profEditAuthority = baseAuthority + environment.msal.profileEditFlow;
export const scopes = [`https://${environment.msal.tenant}.onmicrosoft.com/${environment.msal.middlewareAppIdUriSuffix}/${environment.msal.apiAccessScope}`];


export const apiAccesses = [
	environment.accountApi,
	environment.analyticsApi,
	environment.catalogApi,
	environment.exportApi,
	environment.importApi,
	environment.naturalLanguageProcessingApi,
	environment.protocolApi,
	environment.searchApi,
	environment.ftpApi
];

export const interceptorProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: MsalB2CInterceptor,
	multi: true
}


export const protectedResourceMap = (): Map<string, (string | ProtectedResourceScopes)[] | null> => {
	const resourceMap = new Map<string, (string | ProtectedResourceScopes)[] | null>();

	for (const apiAccess of apiAccesses) {
		resourceMap.set(apiAccess, scopes);
	}

	return resourceMap

}

export const loginLanguage = () => {
	const onboardingLanguageFromUrl = new URLSearchParams(window.location.search).get("language");
	switch (onboardingLanguageFromUrl) {
		case 'German': return Language.DE
		case 'English': return Language.EN
	}
	return GlobalTranslationVar.SELECTED_LANGUAGE === Language.DE ? Language.DE : Language.EN
};
