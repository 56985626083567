import { NgClass } from "@angular/common";
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { NgbModal } from "@commonNodeModules/@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogService } from "@commonServices/confirm-dialog.service";
import { ViewType } from "@enums/view-type";
import { environment } from "@environments/environment";
import { ALERT_POSITION_TYPES } from "@services/alert.service";
import { AnalyticsTrackingFacadeService } from "@services/analytics-tracking-facade.service";
import { ApplicationInsightsService } from "@services/application-insights.service";
import { ErrorHandlerService } from "@services/error-handler.service";
import { GlobalVarService } from "@services/global-var.service";
import { NoResultService } from "@services/no-result.service";
import { PopupService } from "@services/popup.service";
import { SessionService } from "@services/session.service";
import { SoftwareIntegrationCallbackService } from "@services/software-integration-callback.service";
import { UrlService } from "@services/url.service";
import { ViewTypeService } from "@services/view-type.service";
import { WatchlistActionService } from "@services/watchlist-action.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { DeviceDetectorService } from "ngx-device-detector";
import { timer } from "rxjs";
import { filter } from "rxjs/operators";
import { bumpIn, fadeOutBottom } from "./animations/animations";
import { AlertComponent } from "./components/alert/alert.component";
@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	animations: [
		bumpIn,
		fadeOutBottom
	],
	standalone: true,
	providers: [ViewTypeService],
	imports: [NgClass, RouterOutlet, AlertComponent]
})
export class AppComponent implements OnInit, OnDestroy {
	previousUrl: string = null;
	currentUrl: string = null;

	constructor(
		private deviceDetectorService: DeviceDetectorService,
		private viewTypeService: ViewTypeService,
		private router: Router,
		private urlService: UrlService,
		private gtmService: GoogleTagManagerService,
		private globalVarService: GlobalVarService,
		private softwareIntegrationcallbackService: SoftwareIntegrationCallbackService,
		private confirmDialogService: ConfirmDialogService,
		private applicationInsigtsService: ApplicationInsightsService,
		private meta: Meta,
		private errorHandlerService: ErrorHandlerService,
		private modalService: NgbModal,
		private popupService: PopupService,
		private noResultService: NoResultService,
		private watchlistActionService: WatchlistActionService,
		private analyticsTrackingFacadeService: AnalyticsTrackingFacadeService,
		private sessionService: SessionService
	) { }


	ALERT_POSITION_TYPES = ALERT_POSITION_TYPES;
	public homeselector: string;

	private calcAppHeight = () => {
		const doc = document.documentElement
		doc.style.setProperty('--app-height', `${window.innerHeight}px`)
	}

	ngOnInit() {
		const doc = document.documentElement
		if (CSS.supports("( height: 100svh )")) {
			doc.style.setProperty('--app-height', `100svh`)
		} else if (!this.isTouchEnabled()) {
			doc.style.setProperty('--app-height', `100vh`)
		} else {
			window.addEventListener('resize', this.calcAppHeight);
			this.calcAppHeight()
		}

		const errorEventList: ErrorEvent[] = [];
		let errorEventFunction = null;
		if (environment.production) {
			errorEventFunction = (ev: ErrorEvent) => errorEventList.push(ev)
			window.addEventListener('error', errorEventFunction)
		}
		timer(5000).subscribe(async () => {
			document.getElementById("preloadContent")?.remove();
			if (environment.production) {
				this.applicationInsigtsService.init();
				this.errorHandlerService.insertDefaultList(errorEventList, errorEventFunction);
			}

			// Google Tag Manager am Schluss laden
			await this.gtmService.addGtmToDom();
			// Darf erst ausgeführt werden nachdem auch das letzte gtm script geladen wurde
			const consentSubscription = timer(2000).subscribe(() => {
				const script = document.createElement("script");
				script.setAttribute("src", "assets/cookieconsent/consent.js");
				script.setAttribute("type", "text/javascript");
				script.setAttribute("data-cookieconsent", "ignore");
				document.head.appendChild(script);
				consentSubscription.unsubscribe();
			});
		})

		// Accessing previous URL in angular
		this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd)
		).subscribe((event: NavigationEnd) => {
			this.previousUrl = this.currentUrl;
			this.currentUrl = event.url;
			this.urlService.setPreviousUrl(this.previousUrl);
			this.disableHoverOnTouchDevices();
			// Setzen der Indexierung von robots, Startseite, Katalog ohne Inkatalogsuche, AZ, Category und Internationale Kataloge

			if (!(event.url.startsWith('/catalog') || event.url.startsWith('/search?'))) {
				this.analyticsTrackingFacadeService.endSearch();
			}

			if (event.url.startsWith('/catalog') && !event.url.includes('/search?') ||
				event.url.startsWith('/#') ||
				event.url.startsWith('/category') ||
				event.url.startsWith('/a-z') ||
				event.url.startsWith('/international-catalogs') ||
				event.url === '/') {
				this.meta.removeTag('name=robots')
			} else {
				this.meta.addTag({ name: 'robots', content: 'noindex' })
			}
		});

		if (!this.globalVarService.checkStorage()) {
			timer(2000).subscribe(() => {
				this.confirmDialogService.open({
					title: 'global.alert.browser-settings',
					description: 'global.alert.storage-blocked',
					confirm: 'global.alert.storage-blocked-confirm',
					reject: undefined
				}, () => { }, () => { }, false)
			})
		}

		this.confirmDialogService.getConfirmDialog()
			.subscribe(async confirmDialog => {
				const component = (await import('@commonComponents/confirm/confirm.component')).ConfirmComponent;
				const dialogComponent = this.modalService.open(component, {
					centered: true,
					backdrop: confirmDialog.backdropClose ? true : 'static',
					modalDialogClass: confirmDialog.textKeyModel.modalDialogClass

				});
				dialogComponent.componentInstance.textKeyModel = confirmDialog.textKeyModel;
				dialogComponent.componentInstance.hasCloseButton = confirmDialog.closeButton;
				dialogComponent.componentInstance.confirmEventHandler = confirmDialog.confirmEventHandler;
				dialogComponent.componentInstance.dismissEventHandler = confirmDialog.dismissEventHandler;
				dialogComponent.componentInstance.closeDialog.subscribe(() => dialogComponent.close())
			})

		this.popupService.getLoginProfitsDialog()
			.subscribe(async (catalogKey) => {
				const component = (await import('./components/login-profits/login-profits.component')).LoginProfitsComponent;
				const loginProfitDialog = this.modalService.open(component, {
					centered: true,
					windowClass: 'popup',
					backdropClass: 'popup-backdrop',
					size: 'lg'
				});
				loginProfitDialog.componentInstance.catalogKey = catalogKey
				loginProfitDialog.componentInstance.closeDialog.subscribe(() => loginProfitDialog.close())
			})

		this.noResultService.getNoResultDialog()
			.subscribe(async noResult => {
				const centered = this.viewTypeService.currentViewTypeValue !== ViewType.Desktop || noResult.centered
				const component = (await import('./components/no-result/no-result.component')).NoResultComponent;
				const noResultComponent = this.modalService.open(component, {
					centered,
					windowClass: `popup ${centered ? '' : 'below-searchbar'}`,
					backdropClass: 'popup-backdrop',
					size: 'lg',
					beforeDismiss: () => {
						noResultComponent.componentInstance.markSearchbarText();
						return true;
					}
				});
				noResultComponent.componentInstance.noResult = noResult;
				noResultComponent.componentInstance.closeDialog.subscribe(() => noResultComponent.close())
			})

		this.watchlistActionService.getWatchlistMergeDialog()
			.subscribe(async watchlistMergeConfirmDialog => this.openWatchlistMerge(watchlistMergeConfirmDialog));

	}

	private async openWatchlistMerge(watchlistMergeConfirmDialog: { accountCount: number, localCount: number, maxCount: number }) {
		const component = (await import('./components/watchlist-merge-dialog/watchlist-merge-dialog.component')).WatchlistMergeDialogComponent;

		const watchlisMergeDialog = this.modalService.open(component, {
			centered: true,
			backdrop: 'static'

		})

		const model = {
			maxCount: watchlistMergeConfirmDialog?.maxCount,
			accountCount: watchlistMergeConfirmDialog?.accountCount,
			localCount: watchlistMergeConfirmDialog?.localCount
		}
		watchlisMergeDialog.componentInstance.translationModel = model
		watchlisMergeDialog.componentInstance.closeDialog.subscribe(() => watchlisMergeDialog.close())
		watchlisMergeDialog.componentInstance.reOpen.subscribe(async () => this.openWatchlistMerge(model))
	}

	private disableHoverOnTouchDevices() {
		try {
			if ('ontouchstart' in document.documentElement) {
				for (let sheetI = document.styleSheets.length - 1; sheetI >= 0; sheetI--) {
					const sheet = document.styleSheets[sheetI];
					if (sheet.cssRules) {
						for (let ruleI = sheet.cssRules.length - 1; ruleI >= 0; ruleI--) {
							const rule = (sheet.cssRules[ruleI] as any);

							if (rule.selectorText) {
								rule.selectorText = rule.selectorText.replace(':hover', ':active');
							}
						}
					}
				}
			}
		} catch (e) {
		}

	}

	@HostListener('window:beforeunload')
	ngOnDestroy(): void {
		window.removeEventListener('resize', this.calcAppHeight);
	}

	isTouchEnabled() {
		return ('ontouchstart' in window) ||
			(navigator.maxTouchPoints > 0) || ((navigator as any).msMaxTouchPoints > 0);
	}

	public get browser() {
		return this.deviceDetectorService.browser;
	}

	@HostListener("window:beforeunload", ["$event"])
	async unloadNotification($event: any) {
		this.softwareIntegrationcallbackService.onClose();
		this.sessionService.getOrCreateSessionId();
		if (this.globalVarService.getCatalogUploading()) {
			window.onunload = null;
			$event.preventDefault();
			$event.returnValue = false;
			await this.router.navigate([`/administration/catalog/${this.globalVarService.getCatalogUploading().catalogId}/upload/fileupload`])
			return;
		}
	}

}