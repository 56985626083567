<div class="h-100 d-flex flex-column">
	<div class="flex-grow-1">
		<app-mobile-header [catalogKey]="catalogKey"></app-mobile-header>
	</div>
	<main class="h-100 overflow-hidden">
		@switch (currentMobileView) {
			@case (mobileButtonType.CatalogTree) {
				@if (isSearch) {
					@defer {
						<app-catalog-mobile-search-tree [catalog]="catalog" [catalogKey]="catalogKey" />
					}
				} @else {
					@defer {
						<app-catalog-mobile-tree [catalog]="catalog" [catalogKey]="catalogKey" />
					}
				}
			}
			@case (mobileButtonType.CatalogDetails) {
				@defer {
					<app-catalog-mobile-details [catalog]="catalog" [catalogKey]="catalogKey" />
				}
			}
			@case (mobileButtonType.SearchInput) {
				@defer {
					<app-catalog-mobile-search [catalog]="catalog" [catalogKey]="catalogKey" />
				}
			}
			@case (mobileButtonType.Infocenter) {
				@defer {
					<app-catalog-mobile-news [catalog]="catalog" />
				}
			}
			@default {
				@if (currentMobileView === mobileButtonType.CatalogContact || currentMobileView === mobileButtonType.CatalogInfo) {
					@defer {
						<app-catalog-mobile-info [catalog]="catalog" [catalogKey]="catalogKey" />
					}
				}
			}
		}
	</main>
	<div class="flex-grow-1">
		<app-mobile-bar
			#mobileBar
			[disabledMobileBarButtons]="disabledMobileBarButtons"
			[initMobileBarButton]="initMobileBarButton"
			[mobileBarButtons]="mobileBarButtons"
			(buttonChanged)="currentMobileView = $event"></app-mobile-bar>
	</div>
</div>
