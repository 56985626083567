import { RegularExpressions } from "@commonConstValues/regularExpressions";
import { isNullish } from "@commonHelpers/math-utils";
import { ValidationMode } from "@enums/validation-mode";
import { environment } from "@environments/environment";
import { InterfaceType, TrackBaseDto } from "@interfaces/HttpClient/AnalyticsApiTrackingModels";

export class TrackBaseDtoClass implements TrackBaseDto {
	interface?: InterfaceType;
	guid?: string;
	userId?: string | undefined;
	authenticatedUserId?: string | undefined;
	sessionId?: string | undefined;
	referrer?: string | undefined;
	hostAppUserAgent?: string | undefined;
	integrationKey?: string | undefined;
	applicationKey?: string | undefined;
	latitude?: number | undefined;
	longitude?: number | undefined;
	trackTimestamp?: Date;
	protected validationEntries: [string, ValidationMode[]][];

	constructor(params: TrackBaseDto) {
		this.interface = params.interface;
		this.guid = params.guid;
		this.userId = params.userId;
		this.authenticatedUserId = params.authenticatedUserId;
		this.sessionId = params.sessionId;
		this.referrer = params.referrer;
		this.integrationKey = params.integrationKey;
		this.applicationKey = params.applicationKey;
		this.hostAppUserAgent = params.hostAppUserAgent;
		this.trackTimestamp = params.trackTimestamp;
		this.validationEntries = [
			['interface', [ValidationMode.NotNullish, ValidationMode.NotLowerOne]],
			['guid', [ValidationMode.NotEmptyOrNullish, ValidationMode.Guid]],
			['userId', [ValidationMode.NotEmptyOrNullish, ValidationMode.Guid]],
			['authenticatedUserId', [ValidationMode.Guid]],
			['sessionId', [ValidationMode.NotEmptyOrNullish, ValidationMode.Guid]],
			['integrationKey', [ValidationMode.Guid]],
			['applicationKey', [ValidationMode.Guid]],
			['trackTimestamp', [ValidationMode.NotPastDate]]
		]
	}

	protected validate() {
		if (!environment.alertInvalidTrackingValues) {
			return;
		}
		const msg = []
		this.validationEntries.forEach(entry => {
			const memberName = entry[0];
			const value = this[memberName];
			entry[1].forEach(mode => {
				switch (mode) {
					case ValidationMode.NotLowerOne: {
						if (!isNullish(value) && Number.isNaN(value)) {
							msg.push(`value ${value} at ${memberName} is not a number in ${this.constructor.name}`)
						}
						if (!isNullish(value) && +value < 0) {
							msg.push(`value ${value} at ${memberName} is lower than 1 in ${this.constructor.name}`)
						}
						break;
					}
					case ValidationMode.NotLowerZero: {
						if (!isNullish(value) && Number.isNaN(value)) {
							msg.push(`value ${value} at ${memberName} is not a number in ${this.constructor.name}`)
						}
						if (!isNullish(value) && +value < 0) {
							msg.push(`value ${value} at ${memberName} is lower than 0 in ${this.constructor.name}`)
						}
						break;
					}

					case ValidationMode.NotListEmpty: {
						if (!isNullish(value) && value.length < 1) {
							msg.push(`value ${value} at ${memberName} is empty in ${this.constructor.name}`)
						}
						break;
					}


					case ValidationMode.Guid: {
						const guidRegex = new RegExp(RegularExpressions.guid)
						if (!isNullish(value) && !guidRegex.test(value)) {

							msg.push(`value ${value} at ${memberName} is not a guid in ${this.constructor.name}`)
						}
						break;
					}



					case ValidationMode.NotEmptyOrNullish: {
						if (isNullish(value) || value === "") {
							msg.push(`value  ${memberName} is empty in ${this.constructor.name}`)
						}
						break;
					}

					case ValidationMode.NotNullish: {
						if (isNullish(value)) {
							msg.push(`value  ${memberName} is empty in ${this.constructor.name}`)
						}
						break;
					}

					case ValidationMode.Phone: {
						const phoneRegex = new RegExp(RegularExpressions.phone)
						if (!isNullish(value) && !phoneRegex.test(value) && !value.includes('localhost')) {
							msg.push(`value ${value} at ${memberName} is not a phonenumber in ${this.constructor.name}`)
						}
						break;
					}

					case ValidationMode.Mail: {
						const mailRegex = new RegExp(RegularExpressions.mail)
						if (!isNullish(value) && !mailRegex.test(value)) {
							msg.push(`value ${value} at ${memberName} is not a mail in ${this.constructor.name}`)
						}
						break;
					}

					case ValidationMode.NotPastDate: {
						if (!isNullish(value) && !(value instanceof Date)) {
							msg.push(`invalid Date ${value} at ${memberName} in ${this.constructor.name}`)
						}

						// Prüfung soll nur bis zum gestrigen Tag reichen.
						if (!isNullish(value) && (value instanceof Date) && this.calcYesterday().getTime() > value.getTime()) {
							msg.push(`value ${value} at ${memberName} is in the past in ${this.constructor.name}`)
						}
						break;
					}
				}
			})
		})
		if (msg.length > 0) {
			alert(msg.join('\n'))
		}

	}

	// Aus Performanzgründen weil in @app/helpers/date-calculator kendo verwendet wird hier nochmals die Berechnung des gestrigen tages
	private calcYesterday() {
		const removeTimezoneOffset = (date: Date): Date => new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
		const addTimezoneOffset = (date: Date): Date => new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
		const addDayToDate = (date: Date, days: number) => addTimezoneOffset(new Date(removeTimezoneOffset(date).getTime() + (days * 24 * 60 * 60 * 1000)))
		return addDayToDate(new Date(), -1)
	}
}